<template>
    <div class="preview">
        <web-view :url="appUrl" />
    </div>
</template>

<script>
    import WebView from '../components/WebView.vue'
    export default {
        components: {
            WebView
        },
        computed: {
            appUrl() {
                return this.$store.state.app.url
            }
        },
        mounted() {
            if (this.$route.query.action == 'open') {
                this.$store.dispatch('SET_APP', {
                    url: 'https://altenberge.de/' + this.$route.query.url + '/pwa',
                    title: this.$route.query.title
                })
            }
        }
    }
</script>

<style></style>
