<template>
    <div class="a-height">
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <svg
                class="animate-spin -ml-1 mr-3 h-20 w-20 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                v-if="loading"
            >
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </div>
        <div class="px-4 pt-4" v-show="notificationswitch">
            <!-- <button type="button" id="requestPermissionBtn" @click="requestPermission">
                Pushnachrichten aktivieren
            </button> -->
            <label for="permission" class="flex justify-between items-center cursor-pointer">
                <span>Push-Benachrichtigung aktivieren</span>
                <input type="checkbox" ref="permission" id="permission" @change="requestPermission" />
            </label>
            <small style="block w-100" v-show="isIOS()"
                >Push-Benachrichtigungen für iOS-Geräte sind erst ab Version 15.4 aktivierbar.</small
            >
        </div>
        <iframe
            :src="url"
            width="100%"
            height="100%"
            frameborder="0"
            @load="stopLoading"
            v-show="!loading"
            allow="geolocation"
        ></iframe>
    </div>
</template>

<script>
    export default {
        data: () => ({
            loading: true,
            notificationswitch: false
        }),
        props: ['url'],
        methods: {
            stopLoading() {
                this.loading = false
                this.notificationswitch = this.url == 'https://altenberge.de/admin/edit-userConfig.php' ? true : false
                //this.requestPermission()
            },
            requestPermission() {
                var that = this
                if (Notification.permission === 'granted') {
                    localStorage.setItem(
                        'notification-permission',
                        that.$refs.permission.checked ? 'granted' : 'denied'
                    )
                } else if (Notification.permission === 'denied') {
                    localStorage.setItem('notification-permission', 'denied')
                    that.$refs.permission.checked = false
                } else if (Notification.permission === 'default') {
                    Notification.requestPermission(function (choice) {
                        if (choice === 'granted') {
                            localStorage.setItem(
                                'notification-permission',
                                that.$refs.permission.checked ? 'granted' : 'denied'
                            )
                        } else {
                            localStorage.setItem('notification-permission', 'denied')
                            that.$refs.permission.checked = false
                        }
                    })
                }

                if (this.$refs.permission.checked == false && Notification.permission === 'granted') {
                    alert(
                        'Die Rechte für die Push-Benachrichtigungen können nur in den Browsereinstellungen zurückgesetzt werden.'
                    )
                    that.$refs.permission.checked = true
                } else if (Notification.permission === 'denied') {
                    alert('Die Push-Benachrichtigungen lassen sich in den Browsereinstellungen wieder aktivieren.')
                }
                // Notification.requestPermission(function (status) {
                //     if (status == 'granted') {
                //         if (document.querySelector('#requestPermissionBtn')) {
                //             document.querySelector('#requestPermissionBtn').style.display = 'none'
                //         }
                //     }
                // })
            },
            isIOS() {
                return (
                    /iPad|iPhone|iPod/.test(navigator.platform) ||
                    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
                )
            }
        },
        mounted() {
            this.$refs.permission.checked = false
            if (Notification.permission === 'granted') {
                this.$refs.permission.checked = true
            }
        }
    }
</script>

<style lang="scss">
    .a-height {
        height: calc(100vh - 64px);
    }

    input[type='checkbox'] {
        position: relative;
        width: 40px;
        height: 20px;
        -webkit-appearance: none;
        -webkit-appearance: none;
        background: #c6c6c6;
        outline: none;
        cursor: pointer;
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        transition: background 300ms linear;

        // circle
        &::before {
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            top: 0px;
            left: 0px;
            border-radius: 20px;
            background-color: #fff;
            transform: scale(1.1);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            transition: left 300ms linear;
        }

        // move to right
        &:checked {
            background: #158d35;

            &::before {
                left: 20px;
                background-color: #fff;
            }
        }
    }
</style>
